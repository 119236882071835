import React from "react"
import Layout from "../components/layout"
import ScrollAnimation from "react-animate-on-scroll"
import SEO from "../components/seo"

//Image
import PuppetLogo from "../images/puppet.png"
import Bose from "../images/bose.png"
import ContactPuppet from "../components/ContactPuppet"

const Puppet = () => {
  return (
    <Layout>
      <SEO
        title="Puppet Enterprise Automation Check-in"
        description="Have a question about cloud computing and cloud services? Contact Metsi today."
        keywords={["", "", ""]}
        lang="en-gb"
      />
      {/*********** Contact *************/}
      <div className="contact-section" id="contact">
        <div className="outer-container" style={{ padding: "0" }}>
          {/* <h2>Contact Us</h2> */}
          <div className="puppet-hero">
            <img
              src={PuppetLogo}
              alt="Puppet Logo"
              style={{ width: "30%", marginBottom: "50px" }}
            ></img>
            <ScrollAnimation animateIn="animate__fadeInUp" duration="1.3">
              <h1 style={{ textAlign: "center", color: "#FFFFFF" }}>
                Puppet Enterprise Automation Check-in
              </h1>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div
              className="inner-container-2"
              style={{
                justifyContent: "flex-start",
                marginTop: "28px",
                paddingLeft: "80px",
              }}
            >
              <div style={{ display: "flex" }}>
                <img src={Bose} style={{ width: "35%" }}></img>
                <strong style={{ textAlign: "left" }}>
                  You are invited to a free automation check-in with Puppet and
                  an opportunity to receive a brand-new Bose QuietComfort
                  EarBuds* & Puppet goodies!
                </strong>
              </div>
              <p style={{ textAlign: "left" }}>
                As a valued customer, we want to ensure you are maximising your
                Puppet Enterprise subscription, that's why we are offering an
                automation check-in to ensure it's fully up to date and
                including:
              </p>
              <ul style={{ width: "100%", paddingBottom: "30px" }}>
                <li>
                  • Checking current version and recommendations on node usage
                </li>
                <li>• Troubleshooting & solving new use-cases</li>
                <li>• Increase Puppet capabilities within your organisation</li>
                <li>• A full post-automation report to share with your team</li>
              </ul>
              <p style={{ textAlign: "left" }}>
                If you would like to discuss in more detail, complete our
                contact form and a Puppet team member will arrange a time that
                suits you.
              </p>
              <i style={{ fontSize: "x-small", textAlign: "left" }}>
                *Items will be sent once a meeting is accepted, attended and you
                have provided a suitable shipping address. Please allow suitable
                time for shipping
              </i>
            </div>
            <div
              className="inner-container-2"
              style={{
                paddingRight: "80px",
              }}
            >
              <ContactPuppet />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Puppet
