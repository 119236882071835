import React, { useState } from "react"
import ButtonB from "./ButtonB"
import { navigate } from "gatsby"
import "../styles/ContactForm.scss"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "gatsby"

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
  ? process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
  : process.env.GATSBY_RECAPTCHA_KEY

const ContactPuppet = () => {
  const [align, setAlign] = useState(true)
  return (
    <div
      className="inner-container"
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <style dangerouslySetInnerHTML={{ __html: "\n\n" }} />

      <form
        name="contact-puppet"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="form column"
        action="/thank-you"
        // action="thank-you"
        data-netlify-recaptcha="true"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact-puppet" />
        <div className="row">
          <div className="column">
            <label htmlFor="">First Name *</label>
            <input
              name="name"
              type="text"
              tabIndex={1}
              required
              className="form-input"
            />
          </div>
          <div className="column">
            <label htmlFor="">Last Name *</label>
            <input
              name="surname"
              type="text"
              tabIndex={2}
              required
              className="form-input"
            />
          </div>
          {/* <div className="column">
            <label htmlFor="">Phone Number</label>
            <input
              name="phone"
              type="tel"
              tabIndex={2}
              // required
              className="form-input"
            />
          </div> */}
        </div>
        <div className="row">
          <div className="column">
            <label htmlFor="">Email Address*</label>
            <input
              name="email"
              type="email"
              tabIndex={3}
              required
              className="form-input"
            />
          </div>
          <div className="column">
            {" "}
            <label htmlFor="">Company*</label>
            <input
              name="company"
              type="text"
              tabIndex={4}
              required
              className="form-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="column" style={{ width: "100%" }}>
            <label htmlFor="">
              What is your current version of Puppet Enterprise?
            </label>
            <input
              name="puppet version"
              type="text"
              tabIndex={5}
              required
              className="form-input"
              placeholder="i.e. 2019.8 or 'I don't know'"
            />
          </div>
        </div>
        <div className="row">
          <div className="column" style={{ width: "100%" }}>
            <label htmlFor="">
              Please send me headphones after the meeting!*
            </label>
            <select
              name="headphones"
              id="headphones"
              tabIndex={6}
              required
              className={align ? "select-align" : "select-left"}
              defaultValue={"yes"}
            >
              <option value="" defaultValue disabled hidden>
                &#9660;
              </option>
              <option
                value="yes"
                onClick={() => setAlign(false)}
                style={{ textAlign: "left" }}
              >
                Yes
              </option>
              <option
                value="no"
                onClick={() => setAlign(false)}
                style={{ textAlign: "left" }}
              >
                No
              </option>
            </select>
          </div>
        </div>
        <label htmlFor="">Shipping Address</label>
        <textarea
          name="Message"
          tabIndex={5}
          // required
          className="form-text-area"
          defaultValue={""}
        />
        <i style={{ fontSize: "x-small", textAlign: "left" }}>
          *Please provide your shipping address. Puppet will use this address
          solely to ship your headphones (via our distributor), and we'll delete
          your address after we ship the headphones.
        </i>

        <div className="row">
          <label style={{ fontSize: "14px", color: "#626366" }}>
            <input
              type="checkbox"
              id="popi"
              name="popi"
              value="popi"
              required
              style={{
                padding: "0",
                margin: "0",
                verticalAlign: "bottom",
                position: "relative",
                top: "11px",
              }}
            />
            <span style={{ fontWeight: "300" }}>
              {" "}
              &nbsp; I agree and consent to the{" "}
              <Link to="/privacy" style={{ color: "#005b85" }}>
                <strong>Privacy Policy</strong>
              </Link>
              , specifically consenting to Metsi processing and disclosing my
              data and communicating with me according to that policy. I
              understand that I can view and adjust my communication preferences
              at any time.
            </span>
          </label>
        </div>

        <ReCAPTCHA sitekey={RECAPTCHA_KEY} />

        <button
          name="submit"
          type="submit"
          id="contact-submit"
          data-submit="...Sending"
          style={{
            background: "none",
            border: "none",
            marginBottom: "4rem",
          }}
        >
          <ButtonB>Submit</ButtonB>
        </button>
      </form>
    </div>
  )
}

export default ContactPuppet
